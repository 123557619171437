import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const ZZZIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page zzz'} game="zzz">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Zenless Zone Zero (ZZZ) wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Zenless Zone Zero (ZZZ). Check our guides,
          tier lists and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="BlueStacks" />
      <div className="banner-bs-promo">
        <h4>Play your mobile games on PC with BlueStacks!</h4>
        <OutboundLink href="https://bstk.me/oPVIwb6Ny" target="_blank">
          <Button variant="primary">Play now</Button>
        </OutboundLink>
      </div>
      <SectionHeader title="Shortcuts" />
      <h5>Newbie Corner</h5>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/zenless/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_intro.png"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/zenless/guides/reroll-guide"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner guide"
          link="/zenless/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_beginner.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Tips and Tricks"
          link="/zenless/guides/tips-and-tricks"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_tips.jpg"
              alt="Tips and Tricks"
            />
          }
        />
        <CategoryCard
          title="Free Characters"
          link="/zenless/guides/free-characters"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_free.jpg"
              alt="Free Characters"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/zenless/tier-list"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_tier.png"
              alt="Tier List"
            />
          }
        />
      </Row>
      <h5>Database</h5>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Agents"
          link="/zenless/characters"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_characters.png"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Bangboo"
          link="/zenless/bangboo"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_bangboo.png"
              alt="Bangboo list"
            />
          }
        />
        <CategoryCard
          title="Drive Disks"
          link="/zenless/guides/disk-drives-about"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_disk.png"
              alt="Drive Disks"
            />
          }
        />
        <CategoryCard
          title="W-Engines"
          link="/zenless/w-engines"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_engines.png"
              alt="W-Engines"
            />
          }
        />
      </Row>
      <h5>Guides</h5>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Combat system"
          link="/zenless/guides/combat-system"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_combat.jpg"
              alt="Combat system"
            />
          }
        />
        <CategoryCard
          title="Attributes & Specialties"
          link="/zenless/guides/agents-attributes"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_attributes.jpg"
              alt="Attributes & Specialties"
            />
          }
        />
        <CategoryCard
          title="Agents Progression"
          link="/zenless/guides/agents-progression"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_agents.jpg"
              alt="Agents Progression"
            />
          }
        />
        <CategoryCard
          title="Gacha system"
          link="/zenless/guides/gacha-system"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_gacha.png"
              alt="Gacha system"
            />
          }
        />
        <CategoryCard
          title="Game Modes"
          link="/zenless/guides/game-modes"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_modes.png"
              alt="Game Modes"
            />
          }
        />
        <CategoryCard
          title="Inferno Reap"
          link="/zenless/guides/inferno-reap"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_inferno.webp"
              alt="Inferno Reap"
            />
          }
        />
        <CategoryCard
          title="Bangboo"
          link="/zenless/guides/bangboo-intro"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_2.jpg"
              alt="Bangboo"
            />
          }
        />
        <CategoryCard
          title="Drive Disks Stats"
          link="/zenless/guides/disk-drives-stats"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_disk2.png"
              alt="Drive Disks Stats"
            />
          }
        />
        <CategoryCard
          title="Drive Disks Sets"
          link="/zenless/disk-drives"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_disksets.png"
              alt="Drive Disks Sets"
            />
          }
        />
        <CategoryCard
          title="W-Engines system"
          link="/zenless/guides/w-engines"
          image={
            <StaticImage
              src="../../images/zzz/categories/category_engine2.png"
              alt="W-Engines system"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default ZZZIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Zenless Zone Zero (ZZZ) Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Zenless Zone Zero (ZZZ). Check our guides, tier lists and reviews for characters available in the game."
    game="zzz"
  />
);
